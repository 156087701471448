<template>
    <div class="w-100 create-include">
        <div class="content">
            <el-card class="box-card">
                <div class="mod-title first-title">新建定向排除信源方案</div>
                <div class="box-shadow"></div>
                <div class="pad-lr-60 mar-t-30">
                    <el-form :inline="true" :model="form" class="demo-form-inline">
                        <el-form-item label="方案名称">
                            <el-input
                                    v-model="form.name"
                                    placeholder="请输入方案名称"
                                    maxlength="10"
                                    show-word-limit />
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="form.type" placeholder="网站" class="w-120 mar-r-10" @change="addTsource.show = false">
                                <el-option
                                        :label="type"
                                        :value="type"
                                        v-for="(type, index) in sourceTypeOptions"
                                        :key="index">
                                </el-option>
                            </el-select>

                            <el-input
                                    v-model="form.mark"
                                    placeholder="请输入自媒体平台名称"
                                    class="w-200 mar-r-10"
                                    v-if="form.type === '自媒体号'" />
                            <el-input
                                    v-model="form.submark"
                                    placeholder="（选填）请输入作者名称"
                                    class="w-200 mar-r-10"
                                    v-if="form.type === '自媒体号'" />

                            <el-input
                                    v-model="form.mark"
                                    :placeholder="markPlaceholder()"
                                    class="w-410 mar-r-10"
                                    v-if="form.type !== '自媒体号'" />
                            <el-button type="primary single-button" round @click="search()">搜索</el-button>
                        </el-form-item>
                    </el-form>
                    <!-- 穿梭框 -->
                    <div class="mod-transfer pos-r mar-t-30 flex space-between" v-loading="dataLoading">
                        <!-- 左侧 -->
                        <div class="transfer-panel">
                            <div class="transfer-panel__header flex">
                                <span class="b flex-1">搜索结果</span>
                                <p v-if="total <= 50">
                                    <var class="cor-blue">{{ total }}</var> 条结果
                                </p>
                                <p v-else-if="total > 50">
                                    <var class="cor-blue">{{ total }}</var> 条结果，已显示前
                                    <var class="cor-blue">50</var> 条
                                </p>
                            </div>
                            <!-- 未搜索到内容 -->
                            <div
                                    class="transfer-panel__content center not-found"
                                    v-if="addTsource.show && ['网站'].indexOf(form.type) !== -1">
                                <div>
                                    <p>抱歉，未搜索到“{{ addTsource.mark }}”</p>
                                    <p>请尝试搜索网址吧。</p>
                                </div>
                            </div>
                            <!--@end 未搜索到内容 -->
                            <!-- 提交审核 -->
                            <div
                                    class="transfer-panel__content center not-found"
                                    v-if="addTsource.show && ['网站'].indexOf(form.type) === -1">
                                <div class="t-c">
                                    <p>抱歉，未搜索到“{{ addTsource.mark }}”</p>
                                    <el-button type="primary" round class="single-button mar-t-10" @click="toCheck">
                                        提交审核
                                    </el-button>
                                </div>
                            </div>
                            <!--@end 提交审核 -->
                            <!-- 有数据 -->
                            <div class="transfer-panel__content" v-else-if="data.length >= 1">
                              <div class="h-100 flex flex-direction">
                                <div class="mar-lr-30 flex-1">
                                    <ul class="mod-list mar-t-10">
                                        <li>
                                            <h3>
                                                <span class="tag"><var class="b pad-l-25">来源</var></span>
                                                <span class="tag">作者</span>
                                                <span class="tag">网址</span>
                                            </h3>
                                        </li>
                                    </ul>
                                    <div class="mod-list-cont">
                                        <ul class="mod-list">
                                            <li v-for="(row, index) in data" :key="index">
                                              <span class="tag">
                                                <el-checkbox v-model="row.checked" @change="changeChecked(row, index)" :disabled="row.disabled">
                                                </el-checkbox>
                                                {{ getName(row) || '--' }}
                                              </span>
                                              <span class="tag">{{ getAuthor(row) || '--' }}</span>
                                              <span class="tag">
                                                <var v-if="row.state === 'succ'" @click="openLink(row)">{{ getLink(row) || '--' }}</var>
                                                <var v-else-if="row.state === 'wait'" class="cor-fc5">待审核</var>
                                                <var v-else-if="row.state === 'fail'" class="cor-fc5">审核失败</var>
                                              </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p class="summary">
                                    <span v-show="total > 50">没有找到想找的信源？试试更精确的信源名称或网站</span>
                                </p>
                              </div>
                            </div>
                            <!--@end 有数据 -->
                        </div>
                        <!--@end 左侧 -->
                        <!-- 按钮 -->
                        <p class="transfer__buttons">
                            <span
                                    :class="{iconfont: true, 'icon-right-btn': true, jy: !canJoinRight, 'cor-blue': canJoinRight}"
                                    @click="joinRight">
                            </span>
                            <span
                                    :class="{iconfont: true, 'icon-left-btn': true, jy: !canJoinLeft, 'cor-blue': canJoinLeft}"
                                    @click="joinLeft">
                            </span>
                        </p>
                        <!--@end 按钮 -->
                        <!-- 右侧 -->
                        <div class="transfer-panel">
                            <div class="transfer-panel__header flex">
                                <span class="b flex-1">已选信源</span>
                                <p>
                                    <var :class="{'cor-fc5': sourceData.length > 10}">{{ sourceData.length }}</var>/10
                                </p>
                            </div>
                            <!-- 有数据 -->
                            <div class="transfer-panel__content" v-if="sourceData.length >= 1">
                              <div class="h-100 flex flex-direction">
                                <div class="mar-lr-30 flex-1">
                                    <ul class="mod-list mar-t-10">
                                        <li>
                                            <h3>
                                                <span class="tag"><var class="b pad-l-25">来源</var></span>
                                                <span class="tag">作者</span>
                                                <span class="tag">网址</span>
                                            </h3>
                                        </li>
                                    </ul>
                                    <div class="mod-list-cont">
                                        <ul class="mod-list">
                                            <li v-for="(row, index) in sourceData" :key="index">
                                                <span class="tag">
                                                  <el-checkbox v-model="row.checked" @change="changeSourceChecked(row, index)">
                                                  </el-checkbox>
                                                  {{ getName(row) || '--' }}
                                                </span>
                                                <span class="tag">{{ getAuthor(row) || '--' }}</span>
                                                <span class="tag">
                                                    <a v-if="row.state === 'succ'" @click="openLink(row)">{{ getLink(row) || '--' }}</a>
                                                    <a v-else-if="row.state === 'wait'" class="cor-fc5">待审核</a>
                                                    <a v-else-if="row.state === 'fail'" class="cor-fc5">审核失败</a>
                                                </span>

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p class="summary"></p>
                              </div>
                            </div>
                            <!--@end 有数据 -->
                        </div>
                        <!--@end 右侧 -->
                    </div>

                    <div class="button-group pb-20 t-l">
                        <el-button
                                type="primary"
                                round
                                @click="createPlan"
                                v-loading="createLoading"
                                :disabled="createLoading">创建方案
                        </el-button>
                        <el-button round @click="cancel">取消创建</el-button>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>
<script type="application/javascript">
  import {sourceQuery, createSourcePlan} from '@/api/plan'
  import {sourceTypeOptions} from '@/utils/constants'
  import
  {
    canJoinListen,
    toSourceCheck,
    sourceFeature,
    sourceName,
    sourceLink,
    sourceAuthor,
    sourceOpenLink,
    sourceMarkPlaceholder
  } from '@/utils/helpers'

  const {_} = window
  export default {
    name: 'orientation-create-exclude',
    data() {
      return {
        sourceTypeOptions,
        form: {
          name: '',
          type: '网站',
          mark: '',
          submark: ''
        },
        addTsource: {
          category: '',
          mark: '',
          submark: '',
          show: false,
          loading: false
        },
        total: 0,
        data: [], // 搜索信源数据
        sourceData: [], // 已选信源
        checkedData: [], // 选中信源数据
        checkedSourceData: [], // 选中已选信源数据
        dataLoading: false,
        canJoinLeft: false,
        canJoinRight: false,
        createLoading: false
      }
    },
    watch: {},
    methods: {
      getName (row)
      {
        return sourceName(row)
      },
      getLink (row)
      {
        return sourceLink(row)
      },
      getAuthor (row)
      {
        return sourceAuthor(row)
      },
      openLink (row)
      {
        sourceOpenLink(row)
      },
      markPlaceholder ()
      {
        const { type } = this.form

        return sourceMarkPlaceholder(type)
      },
      search() {
        const form = JSON.parse(JSON.stringify(this.form))
        if (form.mark.length < 1) {
          this.$message.warning('搜索内容不能为空')
          return false
        }

        if (!/[^\*]+/.test(form.mark))
        {
          this.$message.warning('请输入有效信源名称或网址')
          return false
        }

        const params = {
          source_type: form.type,
          mark: form.mark,
          submark: form.type === '自媒体号' ? form.submark : '',
          page: 1,
          size: 50 // 搜索结果页最多只显示50条数据
        }

        this.dataLoading = true
        sourceQuery(params).then(res => {
          if (res.data.state) {
            const {data, total} = res.data.data
            this.total = total
            this.data = data.map((item, index) => {
              item.feature = sourceFeature(item)
              item.checked = false
              item.disabled = !canJoinListen(this.sourceData, item)
              return item
            })

            if (_.size(data) >= 1) {
              this.addTsource.show = false
            } else if (_.size(data) < 1) {
              this.addTsource.category = form.type
              this.addTsource.mark = form.mark
              this.addTsource.submark = form.type === '自媒体号' ? form.submark : ''
              this.addTsource.show = true
            }
            this.checkedData = []
          } else {
            this.$message.error(res.data.error)
          }
          this.dataLoading = false
        }).catch(e => {
          window.console.error(e)
          this.$message.error('搜索信源结果失败，服务错误')
        })
      },
      joinLeft()
      {
        if (this.checkedSourceData.length < 1) {
          return false
        }

        const data = this.sourceData.filter((item, index) => {
          const state = this.checkedSourceData.indexOf(index) !== -1
          if (state) {
            this.sourceData[index].checked = false
          }
          return state && canJoinListen(this.data, item)
        })

        this.data = this.data.concat(data)

        this.sourceData = this.sourceData.filter((item, index) => {
          return this.checkedSourceData.indexOf(index) === -1
        })

        this.data = this.data.map((item) => {
          item.disabled = !canJoinListen(this.sourceData, item)
          return item
        })
        this.checkedSourceData = []
        this.canJoinLeft = false
      },
      joinRight()
      {
        if (this.checkedData.length < 1) {
          return false
        }

        const data = this.data.filter((item, index) => {
          const state = this.checkedData.indexOf(index) !== -1
          if (state) {
            this.data[index].checked = false
          }

          return state
        })

        this.sourceData = this.sourceData.concat(data)

        this.data = this.data.filter((item, index) => {
          return this.checkedData.indexOf(index) === -1
        })

        this.checkedData = []

        this.canJoinRight = false
      },
      toCheck() {
        const {category, mark, submark} = this.addTsource
        this.addTsource.loading = true
        const row = toSourceCheck({category, mark, submark})
        row.feature = sourceFeature(row)

        if (canJoinListen(this.sourceData, row)) {
          this.sourceData = this.sourceData.concat(row)
        }

        this.addTsource.show = false
        this.addTsource.loading = false
      },
      changeChecked(row, index) {
        if (row.checked) {
          if (this.checkedData.indexOf(index) === -1) {
            this.checkedData = this.checkedData.concat(index)
          }
        } else {
          this.checkedData = this.checkedData.filter((k) => {
            return k !== index
          })
        }

        this.canJoinRight = this.checkedData.length >= 1
      },
      changeSourceChecked(row, index) {
        if (row.checked) {
          if (this.checkedSourceData.indexOf(index) === -1) {
            this.checkedSourceData = this.checkedSourceData.concat(index)
          }
        } else {
          this.checkedSourceData = this.checkedSourceData.filter((k) => {
            return k !== index
          })
        }

        this.canJoinLeft = this.checkedSourceData.length >= 1
      },
      createPlan() {
        if (this.form.name.length < 1) {
          this.$message.warning('方案名称不能为空')
          return false
        }

        if (this.sourceData.length < 1) {
          this.$message.warning('请先选择要添加的信源，不能为空')
          return false
        }

        if (this.sourceData.length > 10)
        {
          this.$message.warning('已选信源数量超过限制，请减少已选信源数量')
          return false
        }

        const content = this.sourceData.map((row) => {
          delete row.checked
          delete row.disabled
          delete row.feature

          return row
        })
        const data = {
          name: this.form.name,
          type: 'exclude',
          content
        }

        this.createLoading = true
        createSourcePlan(data).then(res => {
          if (res.data.state) {
            this.$message.success('创建方案成功')
            this.cancel()
          } else {
            this.$message.error(res.data.error)
          }
          this.createLoading = false
        }).catch(e => {
          this.$message.error('创建方案失败，服务错误')
          window.console.error(e)
          this.createLoading = false
        })
      },
      cancel ()
      {
        if (this.$route.params.hasOwnProperty('path'))
        {
          if (this.$route.params.path === 'orientation')
          {
            this.$router.push({name: 'orientation', params: {index: 'exclude'}})

            return false
          }
        }

        this.$router.go(-1)
      }
    }
  }
</script>
<style scoped>
    .create-include .first-title {
        margin: 3px 0 23px 40px;
    }

    .create-include >>> .el-form-item {
        display: block;
    }

    .create-include >>> .el-form-item__label {
        font-weight: 600;
    }

    .create-include >>> .el-input--small .el-input__inner {
        height: 35px;
        line-height: 35px;
    }

    .create-include >>> .w-410 .el-input__inner {
        border-radius: 18px;
    }

    .create-include >>> .single-button.el-button {
        margin-top: 10px;
    }

    .mod-transfer {
        width: 1080px;
    }

    .transfer-panel {
        width: 500px;
        height: 560px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 20px 0px rgba(65, 70, 76, 0.07);
        border-radius: 4px;
        overflow: hidden;
    }

    .transfer-panel__header {
        height: 50px;
        line-height: 50px;
        padding: 0 15px;
        border-bottom: 1px solid #F2F2F2;
        width: calc(100% - 30px);
    }

    .transfer-panel__header span, .transfer-panel__header p, .transfer-panel__header var {
        font-size: 12px;
    }

    .transfer-panel__content {
        height: 510px;
    }

    .transfer-panel .not-found {
        font-size: 12px;
        line-height: 22px;
    }

.transfer-panel li .tag{text-align: left;}
.transfer-panel li .tag:nth-child(1){width:100px;}
.transfer-panel li .tag:nth-child(2){width:130px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;padding-right: 5px;}
.transfer-panel li .tag:last-child{width:185px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;}

    .transfer-panel li .el-checkbox {
        margin-right: 10px;
        float: left;
        margin-top: 14px;
    }
.transfer-panel .mod-list-cont{height: calc(100% - 46px);overflow: auto;}
.transfer-panel .mod-list-cont li{height:42px;line-height:42px;}
.transfer-panel .mod-list-cont li:not(:last-child){border-bottom:1px solid #F2F2F2;}
.transfer__buttons{position: absolute;left:calc(50% - 15px);top:170px;}
.transfer__buttons .iconfont{font-size:28px;cursor: pointer;display:block;margin-top: 20px;}
.transfer-panel .flex-1{height: calc(100% - 30px);}
.transfer-panel .summary{margin-block-start:0;margin-block-end:0;font-size: 12px;color: #7c7c7c;padding-bottom: 10px;line-height: 20px;height: 20px;padding-left: 30px;background:#fff;width: calc(100% - 30px);}
</style>
